import { apiUrls } from 'consts'
import { useEffect } from 'react'
import loadRecord from './../utils/loadRecord'

interface useInitProps {
  id: string | undefined
  getRows: Function
  setRows: Function
  setShowOverlay: Function
  setUser: Function
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    if (props.id !== undefined) {
      const userInfoRequest = loadRecord(apiUrls.users, props.id).then((data) => {
        props.setUser(data)
      })

      const authorizedUserAccess = props.getRows({ id: props.id }).then((data) => {
        props.setRows(data)
      })

      Promise.all([userInfoRequest, authorizedUserAccess]).then(() => {
        props.setShowOverlay(false)
      })
    }
  }, [])
}

export default useInit

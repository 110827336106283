import React, { useState } from 'react'
import Layout from 'shared/layouts/Main'
import { Form, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useInit from './hooks/useInit'
import getRows from './utils/getRows'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { getCustomData } from 'shared/components/App/Auth'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { defaultPageSize, roles } from 'consts'
import {
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SearchState,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'
import SearchPanelInput from 'shared/components/SearchPanelInput'
import BackButton from 'shared/components/BackButton'

interface AuthorizedUserAccessProps {
  isAdminPasswordChange?: boolean
}

const AuthorizedUserAccess: React.FunctionComponent<AuthorizedUserAccessProps> = (
  props: AuthorizedUserAccessProps,
) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [user, setUser] = useState<{ email: string; role: string } | null>(null)
  const [rows, setRows] = useState<any>([])

  const columns: { name: string; title: string; encodeHtml?: boolean; getCellValue?: any }[] = [
    { name: 'name', title: 'Name' },
    { name: 'email', title: 'Email' },
    { name: 'trolley_recipient_id', title: 'Recipient ID' },
    { name: 'active', title: 'Active' },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'name', width: 'auto' },
    { columnName: 'email', width: 'auto' },
    { columnName: 'trolley_recipient_id', width: 'auto' },
    { columnName: 'active', width: 'auto' },
  ]

  const params = useParams()

  let id: any = params?.id ?? getCustomData('userId')

  const isAdmin = hasAnyRole([roles.admin])

  useInit({
    id,
    getRows,
    setRows,
    setShowOverlay,
    setUser,
  })

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className='min-mobile-width with-action' />
  )

  const SearchPanelInputComponent = (props) => {
    return SearchPanelInput({
      inputProps: props,
    })
  }

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      headerInsert={isAdmin ? <BackButton url='/users' text='All Users' /> : undefined}
      showCardHeader={true}
    >
      <>
        <Row>
          <Col md={6}>
            <Form.Group className='form-group'>
              <Form.Label>Email</Form.Label>
              <Form.Control disabled={true} value={user?.email ?? ''} />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='form-group'>
              <Form.Label>Role</Form.Label>
              <Form.Control disabled={true} value={user?.role ?? ''} />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Grid rows={rows} columns={columns}>
            <SortingState defaultSorting={[{ columnName: 'concatened', direction: 'asc' }]} />
            <SearchState defaultValue='' />
            <IntegratedFiltering />
            <IntegratedSorting />
            <Toolbar />
            <SearchPanel inputComponent={SearchPanelInputComponent} />
            <PagingState defaultCurrentPage={0} defaultPageSize={defaultPageSize} />
            <IntegratedPaging />
            <Table tableComponent={TableComponent} />
            <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />
          </Grid>
        </Row>
      </>
    </Layout>
  )
}

export default AuthorizedUserAccess

import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js'
import stringToColor from '../utils/stringToColor'
import { Territory } from '../types/AnalyticsProps'

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend)

interface TerritoriesBarChartProps {
  data: Territory[]
}

const TerritoriesBarChart: React.FunctionComponent<TerritoriesBarChartProps> = (
  props: TerritoriesBarChartProps,
) => {
  const colors = props.data.map((item) => stringToColor(item.territory))

  const chartData = {
    labels: props.data.map((item) => item.territory),
    datasets: [
      {
        label: 'Territories',
        data: props.data.map((item) => item.total),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  }

  const totalSum = props.data.reduce((acc, item) => acc + item.total, 0)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.label}: ${tooltipItem.raw.toLocaleString('en-US')}`
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
        ticks: {
          callback: function (value: any, index: any, values: any) {
            const territory = props.data[index].territory || 'Unknown Territory'
            const total = props.data[index].total
            const percentage = ((total / totalSum) * 100).toFixed(2)
            return `${territory} - ${percentage}%`
          },
        },
      },
      y: {
        title: {
          display: false,
        },
        beginAtZero: true,
      },
    },
  }

  return (
    <>
      <h4>Territories</h4>
      <Bar data={chartData} options={options} />
    </>
  )
}

export default TerritoriesBarChart

import Store from 'redux/Store'
import { setMessage } from 'redux/actions'

interface prepareSubmitFormProps {
  submitForm: Function
  data: any
  setShowOverlay: Function
  navigate: Function
  id: string | undefined
  name: string
  successNav: string
  setError?: Function
  instantSuccessMessage?: boolean
}

const prepareSubmitForm = async (props: prepareSubmitFormProps) => {
  props.setShowOverlay(true)

  props
    .submitForm({
      data: props.data,
      setShowOverlay: props.setShowOverlay,
      id: props.id,
      setError: props?.setError,
    })
    .then((response: any) => {
      if (response?.status === 200 || response?.status === 201) {
        Store.dispatch(
          setMessage(
            props.name + ' successfully saved.',
            'success',
            props.instantSuccessMessage ? 'instant' : 'onNavigate',
          ),
        )
        props.navigate(props.successNav)
      }

      props.setShowOverlay(false)
    })

  return true
}

export default prepareSubmitForm

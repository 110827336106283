import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import useInit from './hooks/useInit'
import DeleteModal from 'shared/components/DeleteModal'
import { apiUrls, defaultPageSize, tableColumnWidths, roles } from 'consts'
import getRows from './utils/getRows'
import {
  PagingState,
  SearchState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4'
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid/dist/dx-react-grid'
import HeaderEntitySelect from 'containers/HeaderEntitySelect'
import hasAnyRole from 'shared/utils/hasAnyRole'
import getBrandContent from 'utils/getBrandContent'
import SearchPanelInput from 'shared/components/SearchPanelInput'
import Footer from 'shared/components/Footer'
import { capitalizeFirstLetter } from 'utils/stringUtils'

const Users: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [rows, setRows] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [deleteEntryId, setDeleteEntryId] = useState<boolean | number>(false)

  const [currentEntity, setCurrentEntity] = useState<{} | null>({})

  const columns: { name: string; title: string; encodeHtml?: boolean; getCellValue?: any }[] = [
    { name: 'id', title: 'ID' },
    { name: 'concatened', title: 'Name' },
    { name: 'trolley_recipient_id', title: 'Recipient ID' },
    { name: 'entity', title: 'Entity' },
    { name: 'email', title: 'Email' },
    {
      name: 'account_type',
      title: 'Account Type',
      getCellValue: (row) => capitalizeFirstLetter(row.account_type),
    },
    { name: 'role', title: 'Role' },
    { name: 'action', title: ' ', encodeHtml: false },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'id', width: tableColumnWidths.id },
    { columnName: 'concatened', width: '300px' },
    { columnName: 'trolley_recipient_id', width: '250px' },
    { columnName: 'entity', width: 'auto' },
    { columnName: 'email', width: 'auto' },
    { columnName: 'account_type', width: 'auto' },
    { columnName: 'role', width: 'auto' },
    { columnName: 'action', width: tableColumnWidths.action_8rem },
  ]

  useInit({
    getRows,
    setRows,
    setDeleteEntryId,
    setShowModal,
    setShowOverlay,
    currentEntity,
  })

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className='min-mobile-width with-action' />
  )

  const SearchPanelInputComponent = (props) => {
    return SearchPanelInput({
      inputProps: props,
    })
  }

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      headerInsert={
        hasAnyRole([roles.admin]) ? (
          <HeaderEntitySelect
            setShowOverlay={setShowOverlay}
            handleEntityChanged={(entity) => {
              setCurrentEntity(entity)
            }}
          />
        ) : undefined
      }
    >
      <>
        <Grid rows={rows} columns={columns}>
          <SortingState defaultSorting={[{ columnName: 'concatened', direction: 'asc' }]} />
          <SearchState defaultValue='' />
          <IntegratedFiltering />
          <IntegratedSorting />
          <Toolbar />
          <SearchPanel inputComponent={SearchPanelInputComponent} />
          <PagingState defaultCurrentPage={0} defaultPageSize={defaultPageSize} />
          <IntegratedPaging />
          <Table tableComponent={TableComponent} />
          <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />
        </Grid>
        <DeleteModal
          url={apiUrls.users}
          showModal={showModal}
          setShowModal={setShowModal}
          deleteEntryId={deleteEntryId}
          setDeleteEntryId={setDeleteEntryId}
          entityName='User'
          getRows={getRows}
          setRows={setRows}
        />
      </>
    </Layout>
  )
}

export default Users

import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import PaymentsComponent from 'components/Payments'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import Widget from 'components/Widget'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { roles } from 'consts'
import showUserSwitcher from 'utils/showUserSwitcher'
import HeaderUserSelect from 'containers/HeaderUserSelect'

const RecentPayments: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [showOverlayWidget, setShowOverlayWidget] = useState<boolean>(false)
  const [currentAccountInfo, setCurrentAccountInfo] = useState<any>(null)

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      headerInsert={
        hasAnyRole([roles.user, roles.authorizedUser]) && showUserSwitcher() ? (
          <HeaderUserSelect
            setShowOverlay={setShowOverlay}
            handleAccountInfoChanged={(currentAccountInfo) => {
              setCurrentAccountInfo(currentAccountInfo)
            }}
          />
        ) : undefined
      }
      showOverlay={showOverlay || showOverlayWidget}
    >
      <>
        <h1 className='trolley-header'>Recent Payments</h1>
        <PaymentsComponent
          currentEntity={null}
          enableFilters={false}
          recentPayments={true}
          expandablePayments={false}
          setShowOverlay={setShowOverlay}
          currentAccountInfo={currentAccountInfo}
        />

        <div className='row mt-5'>
          <div className='col-12 text-center'>
            <a className='btn btn-primary' href='/payments '>
              View all payments
            </a>
          </div>
        </div>

        <Widget setShowOverlay={setShowOverlayWidget} currentAccountInfo={currentAccountInfo} />
      </>
    </Layout>
  )
}

export default RecentPayments

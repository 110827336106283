import { apiUrls } from 'consts'
import { useEffect } from 'react'
import loadRecord from './../utils/loadRecord'
import inactivate from '../utils/inactivate'

interface useInitProps {
  id: string | undefined
  getRows: Function
  setRows: Function
  setShowOverlay: Function
  setShowModal: Function
  setDeleteEntryId: Function
  setUser: Function
  navigate: Function
  activationClickCount: number
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    if (props.id !== undefined) {
      const userInfoRequest = loadRecord(apiUrls.users, props.id).then((data) => {
        props.setUser(data)
        if (!data.is_trolley_user) {
          props.navigate('/')
        }
      })

      const authorizedUserAccess = props.getRows().then((data) => {
        props.setRows(data)
      })

      Promise.all([userInfoRequest, authorizedUserAccess]).then(() => {
        props.setShowOverlay(false)
      })
    }
  }, [props.activationClickCount])
}

export default useInit

import Layout from 'shared/layouts/Main'
import { Button, Col, Row } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { roles } from 'consts'
import hasAnyRole from 'shared/utils/hasAnyRole'
import React, { useState } from 'react'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import useInit from './hooks/useInit'

const Account: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [accountInfo, setAccountInfo] = useState<any | undefined>(undefined)

  useInit({
    setAccountInfo,
    setShowOverlay,
  })

  const isUser: boolean = hasAnyRole([roles.user])

  return (
    <Layout footerContent={<Footer />} brandContent={getBrandContent()} showOverlay={showOverlay}>
      <div style={{ minHeight: '75vh' }}>
        <div className='mx-3'>
          <h1 className='trolley-header'>Account Details</h1>
          <Row>
            {accountInfo?.is_trolley_user && (
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.concatened} />
                </Form.Group>
              </Col>
            )}
            <Col md={6}>
              <Form.Group className='form-group'>
                <Form.Label>Email</Form.Label>
                <Form.Control disabled={true} value={accountInfo?.email} />
              </Form.Group>
            </Col>
          </Row>
          {isUser && (
            <Row>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Recipient ID</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.trolley_recipient_id} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Reference ID</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.trolley_reference_id} />
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <Form.Group className='form-group'>
                <a className='btn btn-primary' href='/change-password'>
                  Change Password
                </a>
              </Form.Group>
            </Col>
          </Row>
        </div>

        {isUser && accountInfo?.is_trolley_user === true && (
          <div className='mx-3 mt-3'>
            <h1 className='trolley-header'>Address</h1>
            <Row>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Street 1</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.address?.street1} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Street 2</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.address?.street2} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>City</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.address?.city} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.address?.postal_code} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Country</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.address?.country} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Region</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.address?.region} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control disabled={true} value={accountInfo?.address?.phone} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className='form-group'>
                  <a className='btn btn-primary' href='/change-address'>
                    Change Address
                  </a>
                </Form.Group>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Account

import getAll from 'utils/getAll'
import { apiUrls } from 'consts'
import { axiosGet } from 'shared/api/axios'

interface getRowsReturnType {
  users: Array<any>
  selectedUser: any | null
}

const getRows = async (): Promise<getRowsReturnType> => {
  const users = await getAll(apiUrls.users + '/' + apiUrls.actionGetAuthorizedAccounts)
  const selectedUserResponse = await axiosGet(apiUrls.users + '/' + apiUrls.actionGetSelectedUser)

  return {
    users: users,
    selectedUser: selectedUserResponse.status === 200 ? selectedUserResponse.data : null,
  } as getRowsReturnType
}

export default getRows

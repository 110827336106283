import React, { useEffect, useState } from 'react'
import { setMessage } from 'redux/actions'
import Store from 'redux/Store'
import getPages from 'utils/getPages'
import { NavLink } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import Menu from 'shared/components/Menu'
import Navbar from 'shared/components/Navbar'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { Row } from 'react-bootstrap'
import { roles } from 'consts'
import getEntityLogoUrl from 'shared/utils/getEntityLogoUrl'

interface HeaderProps {
  title: string
  addLink?: string | undefined
  addLinkRoles?: String[]
  message: any
  accountInfo: any
  headerInsert?: React.FunctionComponent | React.ReactElement | false
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const [pages, setPages] = useState<any>([])
  const [hasTopMenu, setHasTopMenu] = useState<boolean>(false)

  const isUserOrAuthorizedUser: boolean = hasAnyRole([roles.user, roles.authorizedUser])

  useEffect(() => {
    getPages('top').then((pages) => {
      setPages(pages)
      setHasTopMenu(!!pages.length)
    })
  }, [])

  useEffect(() => {
    if (props.message.text.length > 0) {
      toast(props.message.text, {
        type: props.message.status,
      })
    }
    window.scrollTo(0, 0)
    Store.dispatch(setMessage('', '', 'onNavigate'))
  }, [window.location.pathname])

  useEffect(() => {
    if (props.message.text.length > 0 && props.message.display === 'instant') {
      toast(props.message.text, {
        type: props.message.status,
        autoClose: 10000,
      })
      window.scrollTo(0, 0)
      Store.dispatch(setMessage('', '', 'onNavigate'))
    }
  }, [props.message])

  let addButton: React.ReactElement | string = ''
  if (
    typeof props.addLink !== 'undefined' &&
    props.addLink &&
    (props.addLinkRoles === undefined || hasAnyRole(props.addLinkRoles))
  ) {
    addButton = (
      <div className='add-cont'>
        <NavLink to={props.addLink}>
          <i className='bi-plus-circle-fill create-icon'></i> &nbsp; Create New
        </NavLink>
      </div>
    )
  }

  return (
    <div className={hasTopMenu ? 'header has-top-menu' : 'header'}>
      <div className='container'>
        <div className='top-bar'>
          <img src={getEntityLogoUrl()} alt='Logo' className='logo' />
          {hasTopMenu && <Menu position='top' />}
          <Navbar title={props.title} />
        </div>
        <Row>
          <div className='col-12 text-white p-t-40 p-b-90'>
            <h3>{props.title}</h3>

            {addButton}
          </div>
        </Row>

        {isUserOrAuthorizedUser && (
          <div className='customer-info'>
            {props.accountInfo?.name && <p>{props.accountInfo.name}</p>}
            {props.accountInfo?.trolley_recipient_id && (
              <p className='minor'>({props.accountInfo.trolley_recipient_id})</p>
            )}
            {props.accountInfo?.email && <p>{props.accountInfo.email}</p>}
            {props.accountInfo?.address && <p>{props.accountInfo.address}</p>}
          </div>
        )}

        {typeof props.headerInsert != 'undefined' && props.headerInsert && (
          <div className='insert-cont'>{props.headerInsert}</div>
        )}
        <ToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </div>
    </div>
  )
}

export default Header

import { Card, Col } from 'react-bootstrap'

interface TotalUnitsProps {
  totalUnits?: number
}

const TotalUnits: React.FunctionComponent<TotalUnitsProps> = (props: TotalUnitsProps) => {
  return (
    <Col sm={12} md={6} className='mb-4'>
      <Card style={{ backgroundColor: '#26C6DA', color: '#fff' }}>
        <Card.Body>
          <Card.Title>Total Units &gt;</Card.Title>
          <Card.Text style={{ fontSize: '2rem' }}>
            {props?.totalUnits?.toLocaleString('en-US')}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default TotalUnits

import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import Widget from 'components/Widget'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { roles } from 'consts'
import showUserSwitcher from 'utils/showUserSwitcher'
import HeaderUserSelect from 'containers/HeaderUserSelect'

const TaxInfo: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [currentAccountInfo, setCurrentAccountInfo] = useState<{} | null>(null)

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      headerInsert={
        hasAnyRole([roles.user, roles.authorizedUser]) && showUserSwitcher() ? (
          <HeaderUserSelect
            setShowOverlay={setShowOverlay}
            handleAccountInfoChanged={(currentAccountInfo) => {
              setCurrentAccountInfo(currentAccountInfo)
            }}
          />
        ) : undefined
      }
      showOverlay={showOverlay}
    >
      <>
        <Widget setShowOverlay={setShowOverlay} currentAccountInfo={currentAccountInfo} />
      </>
    </Layout>
  )
}

export default TaxInfo

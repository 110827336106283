import getAll from 'utils/getAll'
import { apiUrls } from 'consts'

interface getRowsProps {}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.paymentDetailsCsv)

  return data
}

export default getRows

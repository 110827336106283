import { useState } from 'react'
import Select, { MenuListProps } from 'react-select'
import useInit from './hooks/useInit'
import getRows from './utils/getRows'
import useUserChange from './hooks/useUserChange'
import { selectClassNames } from 'shared/assets/conf'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

interface HeaderUserSelectProps {
  setShowOverlay?: Function
  handleAccountInfoChanged?: Function
  currentAccountInfo: any
}

const HeaderUserSelect: React.FunctionComponent<HeaderUserSelectProps> = (
  props: HeaderUserSelectProps,
) => {
  const [rows, setRows] = useState<any>([])
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [initialRender, setInitialRender] = useState<boolean>(true)
  const [currentAccountInfo, setCurrentAccountInfo] = useState<{} | null>(null)

  useInit({
    getRows,
    setRows,
    setCurrentAccountInfo,
    setDataLoaded,
  })

  useUserChange({
    currentAccountInfo,
    initialRender,
    dataLoaded,
    setInitialRender,
    handleAccountInfoChanged: props.handleAccountInfoChanged,
  })

  const handleAccountInfoChange = (value) => {
    if (currentAccountInfo !== value) {
      if (props.setShowOverlay !== undefined) {
        props.setShowOverlay(true)
      }
      setCurrentAccountInfo(value)
    }
  }

  if (currentAccountInfo === null) {
    handleAccountInfoChange(rows[0])
  }

  const MenuList = (props: MenuListProps<any, false, any>) => {
    return (
      <div>
        <PerfectScrollbar style={{ background: 'transparent' }}>{props.children}</PerfectScrollbar>
      </div>
    )
  }

  return (
    <>
      {dataLoaded && (
        <>
          <label className='showing-text'>Showing</label>
          <Select
            classNames={selectClassNames}
            components={{ MenuList }}
            options={rows}
            value={currentAccountInfo}
            getOptionLabel={(option) => option.email}
            getOptionValue={(option) => option.id}
            isClearable={false}
            onChange={(value) => handleAccountInfoChange(value)}
          />
        </>
      )}
      {!dataLoaded && (
        <>
          <label className='showing-text'>Showing</label>
          <Select
            classNames={selectClassNames}
            options={[
              {
                value: 0,
                label: props.currentAccountInfo?.email,
              },
            ]}
            defaultValue={{
              value: 0,
              label: props.currentAccountInfo?.email,
            }}
          />
        </>
      )}
    </>
  )
}

export default HeaderUserSelect

import { useState } from 'react'
import useInit from 'components/Widget/hooks/useInit'
import { Row, Col } from 'react-bootstrap'

interface WidgetProps {
  setShowOverlay: Function
  currentAccountInfo: {} | null
}

const Widget: React.FunctionComponent<WidgetProps> = (props: WidgetProps) => {
  const [widgetUrl, setWidgetUrl] = useState<string | undefined>(undefined)
  const [isTrolleyUser, setIsTrolleyUser] = useState<boolean>(false)

  useInit({
    setWidgetUrl,
    setIsTrolleyUser,
    setShowOverlay: props.setShowOverlay,
    currentAccountInfo: props.currentAccountInfo,
  })

  return (
    <>
      {isTrolleyUser && (
        <iframe
          style={{ overflow: 'hidden', height: '100%', width: '100%', minHeight: '900px' }}
          height='100%'
          width='100%'
          src={widgetUrl}
        />
      )}
      {!isTrolleyUser && (
        <Row style={{ minHeight: '900px' }}>
          <Col className='my-5 lead text-center'>
            Please, select a Trolley User to load a widget.
          </Col>
        </Row>
      )}
    </>
  )
}

export default Widget

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Form, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useInit from './hooks/useInit'
import getRows from './utils/getRows'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { getCustomData } from 'shared/components/App/Auth'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { apiUrls, defaultPageSize, roles, tableColumnWidths } from 'consts'
import {
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SearchState,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'
import SearchPanelInput from 'shared/components/SearchPanelInput'
import BackButton from 'shared/components/BackButton'
import DeleteModal from 'shared/components/DeleteModal'
import activate from './utils/activate'
import inactivate from './utils/inactivate'

interface AuthorizedUsersProps {
  isAdminPasswordChange?: boolean
}

const AuthorizedUsers: React.FunctionComponent<AuthorizedUsersProps> = (
  props: AuthorizedUsersProps,
) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [user, setUser] = useState<{ email: string; role: string } | null>(null)
  const [rows, setRows] = useState<any>([])
  const [deleteEntryId, setDeleteEntryId] = useState<boolean | number>(false)
  const [activationClickCount, setActivationClickCount] = useState<number>(0)

  const columns: { name: string; title: string; encodeHtml?: boolean; getCellValue?: any }[] = [
    { name: 'email', title: 'Email' },
    { name: 'active', title: 'Active' },
    { name: 'action', title: ' ', encodeHtml: false },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'email', width: 'auto' },
    { columnName: 'active', width: 'auto' },
    { columnName: 'action', width: tableColumnWidths.action_8rem },
  ]

  const navigate = useNavigate()
  const params = useParams()

  let id: any = params?.id ?? getCustomData('userId')

  const isAdmin = hasAnyRole([roles.admin])

  const getRowsConfigured: any = () => {
    return getRows({
      id: id,
      setDeleteEntryId: setDeleteEntryId,
      setShowOverlay: setShowOverlay,
      setShowModal: setShowModal,
      activate: activate,
      inactivate: inactivate,
      setActivationClickCount: setActivationClickCount,
    })
  }

  useInit({
    id,
    getRows: getRowsConfigured,
    setRows,
    setShowOverlay,
    setShowModal,
    setDeleteEntryId,
    setUser,
    navigate,
    activationClickCount,
  })

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className='min-mobile-width with-action' />
  )

  const SearchPanelInputComponent = (props) => {
    return SearchPanelInput({
      inputProps: props,
      addUrl: '/add-authorized-user/' + id,
      addText: 'Add',
    })
  }

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      headerInsert={isAdmin ? <BackButton url='/users' text='All Users' /> : undefined}
      showCardHeader={true}
    >
      <>
        <Row>
          <Col md={6}>
            <Form.Group className='form-group'>
              <Form.Label>Email</Form.Label>
              <Form.Control disabled={true} value={user?.email ?? ''} />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='form-group'>
              <Form.Label>Role</Form.Label>
              <Form.Control disabled={true} value={user?.role ?? ''} />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Grid rows={rows} columns={columns}>
            <SortingState defaultSorting={[{ columnName: 'email', direction: 'asc' }]} />
            <SearchState defaultValue='' />
            <IntegratedFiltering />
            <IntegratedSorting />
            <Toolbar />
            <SearchPanel inputComponent={SearchPanelInputComponent} />
            <PagingState defaultCurrentPage={0} defaultPageSize={defaultPageSize} />
            <IntegratedPaging />
            <Table tableComponent={TableComponent} />
            <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />
          </Grid>
          <DeleteModal
            url={apiUrls.authorizedUsers}
            showModal={showModal}
            setShowModal={setShowModal}
            deleteEntryId={deleteEntryId}
            setDeleteEntryId={setDeleteEntryId}
            entityName='Authorized User'
            getRows={getRowsConfigured}
            setRows={setRows}
          />
        </Row>
      </>
    </Layout>
  )
}

export default AuthorizedUsers

import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import PaymentsComponent from 'components/Payments'
import HeaderEntitySelect from 'containers/HeaderEntitySelect'
import hasAnyRole from 'shared/utils/hasAnyRole'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { roles } from 'consts'
import { useParams } from 'react-router-dom'
import showUserSwitcher from 'utils/showUserSwitcher'
import HeaderUserSelect from 'containers/HeaderUserSelect'

const Statement: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [currentEntity, setCurrentEntity] = useState<{} | null>({})
  const [currentAccountInfo, setCurrentAccountInfo] = useState<{} | null>({})

  const params = useParams()

  const id: string | undefined = params?.id ?? undefined

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      headerInsert={
        hasAnyRole([roles.admin]) ? (
          <HeaderEntitySelect
            setShowOverlay={setShowOverlay}
            handleEntityChanged={(entity) => {
              setShowOverlay(false)
              setCurrentEntity(entity)
            }}
          />
        ) : hasAnyRole([roles.user, roles.authorizedUser]) && showUserSwitcher() ? (
          <HeaderUserSelect
            setShowOverlay={setShowOverlay}
            handleAccountInfoChanged={(currentAccountInfo) => {
              setCurrentAccountInfo(currentAccountInfo)
            }}
          />
        ) : undefined
      }
    >
      <>
        <PaymentsComponent
          currentEntity={currentEntity}
          currentAccountInfo={currentAccountInfo}
          setShowOverlay={setShowOverlay}
          includeStatements={true}
          filteredPayment={id}
        />
      </>
    </Layout>
  )
}

export default Statement

import { apiUrls } from 'consts'
import { useEffect } from 'react'
import getAll from 'utils/getAll'

interface useInitRecentPaymentsProps {
  setWidgetUrl: Function
  setIsTrolleyUser: Function
  setShowOverlay: Function
  currentAccountInfo: {} | null
}

const useInit = (props: useInitRecentPaymentsProps) => {
  useEffect(() => {
    const widgetRequest = getAll(apiUrls.users + '/' + apiUrls.actionWidget).then(
      (data: { is_trolley_user: boolean; widgetLink?: string }) => {
        props.setIsTrolleyUser(data.is_trolley_user)
        props.setWidgetUrl(data.widgetLink)
      },
    )

    Promise.all([widgetRequest]).then(() => {
      props.setShowOverlay(false)
    })
  }, [props.currentAccountInfo])
}

export default useInit

import getAll from 'utils/getAll'
import { apiUrls } from 'consts'

interface getRowsProps {
  id: string
}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.authorizedUserAccess + '/' + props.id)

  data.forEach((row: any, index: number, dataArray: any) => {
    dataArray[index].active = row.active === 1 ? 'Yes' : 'No'
  })

  return data
}

export default getRows

import { axiosPost } from 'shared/api/axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import { AxiosError } from 'axios'
import setFormValidationError from 'shared/utils/setFormValidationError'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  id: string | undefined
  setError: Function
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  const formData = {
    ...props.data,
  }

  const catchFunc = (err: AxiosError) => {
    if (err.response!.status === 422) {
      let mappedAttributes: Map<string, string> = new Map()
      mappedAttributes.set('email', 'email')
      setFormValidationError(err, props.setError, mappedAttributes, 'email')
    } else {
      const message = err?.response?.data?.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
    }
  }

  response = await axiosPost(
    apiUrls.authorizedUsers + '/' + props.id + '/' + apiUrls.actionCreate,
    formData,
    catchFunc,
  )

  return response
}

export default submitForm

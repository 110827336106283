import { useState } from 'react'
import useInit from './hooks/useInit'
import {
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
  Filter,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'
import { IonIcon } from '@ionic/react'
import { add, download, filter as filterIcon, list } from 'ionicons/icons'
import getRows from './utils/getRows'
import downloadStatements from './utils/downloadStatements'
import SearchPanelInput from 'shared/components/SearchPanelInput'
import PaymentsFilter from './filter'
import { Download } from 'react-bootstrap-icons'
import { Button, Row, Col } from 'react-bootstrap'
import { formatDate, stringToDate } from 'utils/dateUtils'
import { Badge } from 'react-bootstrap'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { apiUrls, roles } from 'consts'
import getFileIcon from 'shared/utils/getFileIcon'
import customFilterWithStatements from './utils/customFilterWithStatements'
import exportStatements from './utils/exportStatements'
import { NavLink, useNavigate } from 'react-router-dom'
import ActionButtons from 'shared/components/ActionButtons'
import DeleteModal from 'shared/components/DeleteModal'
import refreshCsv from 'utils/refreshCsv'

interface PaymentProps {
  currentEntity: {} | null
  currentAccountInfo?: {} | null | undefined
  setShowOverlay: Function
  enableFilters?: boolean
  recentPayments?: boolean
  expandablePayments?: boolean
  includeStatements?: boolean
  filteredPayment?: string
}

const Payments: React.FunctionComponent<PaymentProps> = (props: PaymentProps) => {
  const isAdmin: boolean = hasAnyRole([roles.admin])
  const navigate = useNavigate()

  const [enableFilters] = useState<boolean | undefined>(props.enableFilters ?? true)
  const [recentPayments] = useState<boolean | undefined>(props.recentPayments ?? false)
  const [includeStatements] = useState<boolean | undefined>(props.includeStatements ?? false)
  const [expandablePayments] = useState<boolean | undefined>(props.expandablePayments ?? true)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [deleteEntryId, setDeleteEntryId] = useState<boolean | number>(false)

  const [rows, setRows] = useState<any>([])
  const [filter, setFilter] = useState<{} | null>({})
  const [filterVisible, setFilterVisible] = useState<boolean>(false)
  const [selection, setSelection] = useState<Array<any>>([])
  const [selectedIds, setSelectedIds] = useState<any>([])
  const [detailSelection, setDetailSelection] = useState<any>({})
  const [idsStatementsSelected, setIdsStatementsSelected] = useState<any>({})
  const [isDownloadSelectedLoading, setIsDownloadSelectedLoading] = useState<boolean>(false)
  const [refreshCsvCount, setRefreshCsvCount] = useState<number>(0)
  const [defaultHiddenColumnNames] = useState<Array<string>>(
    isAdmin
      ? ['trolley_payment_id', 'trolley_recipient_id', 'payout_method_info']
      : ['name', 'trolley_payment_id', 'trolley_recipient_id', 'payout_method_info'],
  )
  const [defaultHiddenColumnSubGridNames] = useState<Array<string>>(
    isAdmin ? [] : ['csv_status', 'action'],
  )

  const [columnExtensions] = useState<Array<Table.ColumnExtension>>([
    { columnName: 'source_amount', align: 'right' },
    { columnName: 'target_amount', align: 'right' },
    { columnName: 'target_currency', align: 'center' },
    { columnName: 'status', align: 'center' },
  ])

  const [integratedFilteringColumnExtensions] = useState<
    Array<IntegratedFiltering.ColumnExtension>
  >([
    {
      columnName: 'processed_at',
      predicate: (value: any, filter: Filter, row: any) => {
        return includeStatements
          ? customFilterWithStatements(value, filter, row)
          : IntegratedFiltering.defaultPredicate(value, filter, row)
      },
    },
    {
      //disable filtering by hidden column `name` for users
      columnName: 'name',
      predicate: (value: any, filter: Filter, row: any) => {
        return hasAnyRole([roles.admin])
          ? IntegratedFiltering.defaultPredicate(value, filter, row)
          : false
      },
    },
  ])

  //extra getRows configuration (`recentPayments` parameter)
  const getDataConfigured = () => {
    return getRows({
      filter: filter,
      recent: recentPayments,
      statements: includeStatements,
      filteredPayment: props.filteredPayment,
    })
  }

  const [dateFormatter] = useState<any>({
    for: ['processed_at'],
    formatterComponent: (props) => formatDate(stringToDate(props.row.processed_at)),
    editorComponent: undefined,
    availableFilterOperations: undefined,
  })

  const [statusFormatter] = useState<any>({
    for: ['status'],
    formatterComponent: (props) => {
      switch (props.row.status) {
        case 'processed':
          return <Badge bg='success'>{props.row.status}</Badge>
        case 'pending':
          return <Badge bg='warning'>{props.row.status}</Badge>
        default:
          return <Badge bg='primary'>{props.row.status}</Badge>
      }
    },
    editorComponent: undefined,
    availableFilterOperations: undefined,
  })

  const [intlFormatter] = useState<Intl.NumberFormat>(
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  )

  const formatAmount = (value: any): string | null => {
    if ([undefined, null].includes(value)) {
      return null
    }
    return intlFormatter.format(parseFloat(value))
  }

  const [amountFormatter] = useState<any>({
    for: ['source_amount', 'target_amount'],
    formatterComponent: (props) => {
      return formatAmount(props.value)
    },
    editorComponent: undefined,
    availableFilterOperations: undefined,
  })

  const columns: { name: string; title: string; encodeHtml?: boolean; getCellValue?: any }[] = [
    {
      name: 'processed_at',
      title: 'Date',
    },
    {
      name: 'name',
      title: 'Recipient Name',
    },
    {
      name: 'memo',
      title: 'Detail',
    },
    {
      name: 'source_amount',
      title: 'USD Amount',
    },
    {
      name: 'target_currency',
      title: 'Currency',
    },
    {
      name: 'target_amount',
      title: 'Amount',
    },
    {
      name: 'method_display',
      title: 'Method',
    },
    {
      name: 'status',
      title: 'Status',
    },
    //add these 2 columns because of including these attributes to search / filtering (columns are hidden)
    { name: 'trolley_payment_id', title: 'Payment #' },
    { name: 'trolley_recipient_id', title: 'Recipient #' },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'processed_at', width: '94px' },
    { columnName: 'name', width: '280px' },
    { columnName: 'memo', width: isAdmin ? '223px' : '503px' },
    { columnName: 'source_amount', width: '130px' },
    { columnName: 'target_currency', width: '106px' },
    { columnName: 'target_amount', width: '130px' },
    { columnName: 'method_display', width: '120px' },
    { columnName: 'status', width: '101px' },
    { columnName: 'trolley_payment_id', width: 0 },
    { columnName: 'trolley_recipient_id', width: 0 },
  ]

  const filenameFormat = (row: any) => {
    const opts = { size: '28' }
    var fileExt = row.file_name.split('.').pop()
    const linkIcon = getFileIcon(fileExt, opts)

    return (
      <a
        onClick={(e) => {
          e.preventDefault()
          downloadStatements({
            statementsSelected: row.id,
            setIsDownloadSelectedLoading,
            setShowOverlay: props.setShowOverlay,
          })
        }}
        href='#'
      >
        {linkIcon}
      </a>
    )
  }

  const detailColumns: ReadonlyArray<Column> = [
    { name: 'company_name', title: 'Company' },
    { name: 'statement_period', title: 'Statement Period' },
    { name: 'file_name', title: 'Filename' },
    { name: 'download', title: 'Download', getCellValue: (row) => filenameFormat(row) },
    { name: 'csv_status', title: 'CSV Status' },
    {
      name: 'action',
      title: ' ',
      getCellValue: (row) => {
        let actions: Array<any> = []

        const refreshCsvFunction = () => {
          refreshCsv({
            id: row.id,
            setShowOverlay: props.setShowOverlay,
            setRefreshCsvCount: setRefreshCsvCount,
          })
        }

        if (row.file_name?.endsWith('.csv')) {
          actions.push({
            type: 'refresh-data',
            id: row.id,
            function: refreshCsvFunction,
          })
        }

        actions.push(
          ...[
            {
              type: 'u',
              url: '/edit-statement/' + row.id,
            },
            {
              type: 'd',
              id: row.id,
              url: '/delete-statement/' + row.id,
              setDeleteEntryId: setDeleteEntryId,
              setShowModal: setShowModal,
            },
          ],
        )

        return isAdmin ? <ActionButtons buttons={actions} /> : null
      },
    },
  ]

  const detailColumnWidths: TableColumnWidthInfo[] = [
    { columnName: 'company_name', width: isAdmin ? '15%' : '25%' },
    { columnName: 'statement_period', width: isAdmin ? '20%' : '25%' },
    { columnName: 'file_name', width: isAdmin ? '20%' : '25%' },
    { columnName: 'download', width: isAdmin ? '15%' : '20%' },
    { columnName: 'csv_status', width: isAdmin ? '15%' : 'auto' },
    { columnName: 'action', width: isAdmin ? '10%' : 'auto' },
  ]

  useInit({
    getRows: getDataConfigured,
    setRows,
    setShowOverlay: props.setShowOverlay,
    filter,
    currentEntity: props.currentEntity,
    currentAccountInfo: props.currentAccountInfo,
    filteredPayment: props.filteredPayment,
    refreshCsvCount: refreshCsvCount,
  })

  const handleRowSelectionChanged = (row: any, currentRowSelection: Array<string | number>) => {
    setDetailSelection((originalVal) => {
      return { ...originalVal, ...{ [row.id]: currentRowSelection } }
    })

    let currentRowStatementIds: Array<number> = []

    row.statements
      .filter((_, index) => currentRowSelection.includes(index))
      .forEach((r, index) => {
        currentRowStatementIds.push(r.id)
      })
    setIdsStatementsSelected((originalVal) => {
      if (currentRowSelection.length < 1) {
        delete originalVal[row.id]
        return originalVal
      }

      return { ...originalVal, ...{ [row.id]: currentRowStatementIds } }
    })
  }

  const onParentRowSelectionChanged = (currSelection: Array<number | string>) => {
    const addedIndexes = currSelection.filter((val) => !selection.includes(val))
    const removedIndexes = selection.filter((val) => !currSelection.includes(val))

    rows.forEach((r, index) => {
      const isRowAdded: boolean = addedIndexes.includes(index)
      const isRowRemoved: boolean = removedIndexes.includes(index)

      if (isRowAdded || isRowRemoved) {
        let currDetailSelection = isRowAdded
          ? Array(r.statements.length)
              .fill(null)
              .map((_, i) => i)
          : []
        handleRowSelectionChanged(r, currDetailSelection)
      }
    })
    setSelection(currSelection)
  }

  const onRowSelectionChanged = (currSelection: Array<number | string>) => {
    const filteredRows = rows.filter((_, index) => currSelection.includes(index))
    let selectedIds: Array<any> = []
    filteredRows.forEach((r) => {
      if (r.statementsCount > 0) {
        selectedIds.push(r.id)
      }
    })

    setSelectedIds(selectedIds)
    setSelection(currSelection)
  }

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className='small with-action min-mobile-width' />
  )

  const TableCellComponent = (componentProps) => {
    return (
      <Table.Cell
        {...componentProps}
        style={{ cursor: props.recentPayments ? 'pointer' : 'default' }}
        onClick={(e) => {
          //Redirect from Recent Payments to Statements page
          if (props.recentPayments) {
            navigate('/statements/' + componentProps.row.id)
          }
        }}
      />
    )
  }

  const SearchPanelAdminInputStatementsComponent = (inputPanelProps) => {
    const insert: any = (
      <>
        {SearchPanelInsert}
        <NavLink to={'/statements-list'} className='btn btn-primary'>
          <IonIcon
            icon={list}
            className='ionicon'
            style={{ fontSize: '16px', marginBottom: '-3px' }}
          />
          List
        </NavLink>
        <Button
          variant='primary'
          onClick={(e) => {
            e.preventDefault()
            exportStatements({
              setShowOverlay: props.setShowOverlay,
            })
          }}
        >
          <IonIcon
            icon={download}
            className='ionicon'
            style={{ fontSize: '16px', marginBottom: '-3px' }}
          />
          Export CSV
        </Button>
        <NavLink to={'/add-statement'} className='btn btn-primary'>
          <IonIcon
            icon={add}
            className='ionicon'
            style={{ fontSize: '16px', marginBottom: '-3px' }}
          />
          Add
        </NavLink>
      </>
    )

    return SearchPanelInput({
      insert: insert,
      addUrl: '/import-statements',
      addText: 'Import CSV',
      inputProps: inputPanelProps,
    })
  }

  const SearchPanelInsert = (
    <a className='btn btn-light' onClick={() => setFilterVisible(!filterVisible)}>
      <IonIcon icon={filterIcon} className='ionicon' style={{ fontSize: '12px' }} />
      {!filterVisible ? 'Show Filters' : 'Hide Filters'}
    </a>
  )

  const SearchPanelInputComponent = (props) => {
    return SearchPanelInput({
      insert: enableFilters ? SearchPanelInsert : undefined,
      inputProps: props,
    })
  }

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  const TableSelectionCellComponent = (props) => {
    return props.row.statementsCount > 0 ? <TableSelection.Cell {...props} /> : <td></td>
  }

  const displayPaymentMethodConcatened = (row: any) => {
    if (row.method_display !== 'Check') {
      return row.method_display
    }

    return row.check_number !== null
      ? row.method_display + ' #' + row.check_number
      : row.method_display
  }

  const PaymentDetailsComponent = (props) => {
    return (
      <div className='card'>
        <div className='mt-3 py-3'>
          <div className='text-left d-flex ps-5 mb-3'>
            <Row className='w-100'>
              <Col md={4} className='payment-info'>
                <span className='payment-info-header'>Payment ID:</span>{' '}
                <span className='payment-info-text'>{props.row.trolley_payment_id}</span>
              </Col>
              <Col md={5} className='payment-info'>
                <span className='payment-info-header'>Recipient ID:</span>{' '}
                <span className='payment-info-text'>{props.row.trolley_recipient_id}</span>
              </Col>
              <Col md={3} className='payment-info'>
                <span className='payment-info-header'>Payout Method:</span>{' '}
                <span className='payment-info-text'>
                  <>{displayPaymentMethodConcatened(props.row)}</>
                </span>
              </Col>
            </Row>
          </div>
          <div className='text-left d-flex ps-5'>
            <Row className='w-100'>
              <Col md={4} className='payment-info'>
                <span className='payment-info-header'>Payment Amount:</span>{' '}
                <span className='payment-info-text'>
                  {formatAmount(props.row.source_amount)} {props.row.source_currency}
                </span>
                <br />
                {![null, undefined].includes(props.row.recipient_fees) &&
                  props.row.recipient_fees > 0 && (
                    <>
                      <span className='payment-info-header'>Fees:</span>{' '}
                      <span className='payment-info-text'>
                        -{formatAmount(props.row.recipient_fees)} {props.row.source_currency}
                      </span>
                    </>
                  )}
                <br />
                <span className='payment-info-header'> You Receive:</span>{' '}
                <span className='payment-info-text'>
                  {formatAmount(props.row.target_amount)} {props.row.target_currency}
                </span>
              </Col>
              <Col md={5} className='payment-info'>
                <span className='payment-info-text'>{props.row.payout_method_info}</span>
              </Col>
              {props.row.estimated_delivery_at && (
                <Col md={3} className='payment-info'>
                  <span className='payment-info-header'>Est. Delivery At:</span>{' '}
                  <span className='payment-info-text'>
                    {formatDate(stringToDate(props.row.estimated_delivery_at))}
                  </span>
                </Col>
              )}
            </Row>
          </div>
        </div>

        {props.row?.statements?.length > 0 && (
          <Grid rows={props.row.statements} columns={detailColumns}>
            <SelectionState
              selection={detailSelection[props.row.id] ?? []}
              onSelectionChange={(currDetailSelection) => {
                handleRowSelectionChanged(props.row, currDetailSelection)
              }}
            />
            <Table />
            <TableColumnResizing
              defaultColumnWidths={detailColumnWidths}
              resizingMode='nextColumn'
            />
            <TableSelection />
            <TableHeaderRow />
            <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnSubGridNames} />
          </Grid>
        )}
      </div>
    )
  }

  const PaymentDetailsToggleCellComponent = (props) => {
    return <TableRowDetail.ToggleCell {...props} />
  }

  return (
    <>
      {enableFilters && (
        <PaymentsFilter
          setShowOverlay={props.setShowOverlay}
          handleFilter={(filter) => setFilter(filter)}
          currentEntity={props.currentEntity}
          filterVisible={filterVisible}
        />
      )}
      <Grid rows={rows} columns={columns}>
        <DataTypeProvider {...dateFormatter} />
        <DataTypeProvider {...statusFormatter} />
        <DataTypeProvider {...amountFormatter} />
        <SortingState defaultSorting={[{ columnName: 'processed_at', direction: 'desc' }]} />
        <SearchState defaultValue='' />
        <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
        <IntegratedSorting />
        {!recentPayments && <PagingState defaultCurrentPage={0} defaultPageSize={20} />}
        {!recentPayments && <IntegratedPaging />}
        <Toolbar />
        <SearchPanel
          inputComponent={
            props.includeStatements && isAdmin
              ? SearchPanelAdminInputStatementsComponent
              : SearchPanelInputComponent
          }
        />
        <SelectionState
          selection={selection}
          onSelectionChange={
            includeStatements ? onParentRowSelectionChanged : onRowSelectionChanged
          }
        />
        <IntegratedSelection />
        <Table
          tableComponent={TableComponent}
          cellComponent={TableCellComponent}
          columnExtensions={columnExtensions}
        />
        <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
        <TableSelection
          showSelectAll
          selectByRowClick={false}
          cellComponent={TableSelectionCellComponent}
        />
        <TableHeaderRow showSortingControls />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <RowDetailState />
        {(expandablePayments || includeStatements) && (
          <TableRowDetail
            toggleCellComponent={PaymentDetailsToggleCellComponent}
            contentComponent={PaymentDetailsComponent}
          />
        )}
        {!recentPayments && <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />}
      </Grid>
      <>
        <Button
          disabled={
            (includeStatements
              ? Object.keys(idsStatementsSelected).length < 1
              : Object.keys(selectedIds).length < 1) || isDownloadSelectedLoading
          }
          onClick={(e) =>
            downloadStatements({
              statementsSelected: includeStatements ? idsStatementsSelected : undefined,
              paymentIdsSelected: includeStatements ? undefined : selectedIds,
              setIsDownloadSelectedLoading,
              setShowOverlay: props.setShowOverlay,
            })
          }
          variant='primary'
          size='sm'
          className='float-right'
        >
          {isDownloadSelectedLoading ? (
            <>
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
              &nbsp;
            </>
          ) : (
            <Download />
          )}
          &nbsp; Download Statements
        </Button>

        {isAdmin && (
          <DeleteModal
            url={apiUrls.paymentDetails}
            showModal={showModal}
            setShowModal={setShowModal}
            deleteEntryId={deleteEntryId}
            setDeleteEntryId={setDeleteEntryId}
            entityName='Statement'
            getRows={getDataConfigured}
            setRows={setRows}
          />
        )}
      </>
    </>
  )
}

export default Payments

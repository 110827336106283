import getAll from 'utils/getAll'
import { apiUrls } from 'consts'
import ActionButtons from 'shared/components/ActionButtons'

interface getRowsProps {
  id: string
  setDeleteEntryId: Function
  setShowModal: Function
  setShowOverlay: Function
  activate: Function
  inactivate: Function
  setActivationClickCount: Function
}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.authorizedUsers + '/' + props.id)

  data.forEach((row: any, index: number, dataArray: any) => {
    const isActive: boolean = row.active === 1
    dataArray[index].active = isActive ? 'Yes' : 'No'

    const activateOrInactivateFunction = () => {
      return isActive
        ? props.inactivate({
            id: row.id,
            setShowOverlay: props.setShowOverlay,
            setActivationClickCount: props.setActivationClickCount,
          })
        : props.activate({
            id: row.id,
            setShowOverlay: props.setShowOverlay,
            setActivationClickCount: props.setActivationClickCount,
          })
    }

    dataArray[index].action = (
      <ActionButtons
        buttons={[
          {
            type: isActive ? 'inactivate' : 'activate',
            id: row.id,
            function: activateOrInactivateFunction,
          },
          {
            type: 'd',
            id: dataArray[index].id,
            url: '/delete-authorized-user/' + row.id,
            setDeleteEntryId: props.setDeleteEntryId,
            setShowModal: props.setShowModal,
          },
        ]}
      />
    )
  })

  return data
}

export default getRows

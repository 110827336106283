import { combineReducers } from 'redux'
import entityName from 'redux/reducers/entityName'
import settings from 'redux/reducers/settings'
import identity from 'shared/redux/reducers/identity'
import message from 'shared/redux/reducers/message'
import customData from 'shared/redux/reducers/customData'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['entityName', 'identity', 'message', 'customData', 'settings'],
  stateReconciler: autoMergeLevel2,
}

const combinedReducers = combineReducers({
  entityName,
  identity,
  message,
  customData,
  settings,
})

const persistedReducer = persistReducer<any>(persistConfig, combinedReducers)

export default persistedReducer

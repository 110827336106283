import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Button, Form, Row, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import InputWrapper from 'shared/components/InputWrapper'
import resAddAuthorizedUser from './resolvers/resAddAuthorizedUser'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import prepareSubmitForm from 'shared/utils/prepareSubmitForm'
import submitForm from './utils/submitForm'
import getBrandContent from 'utils/getBrandContent'
import BackButton from 'shared/components/BackButton'
import Footer from 'shared/components/Footer'
import getDomain from 'utils/getDomain'
import { Col } from 'reactstrap'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { roles } from 'consts'

const AddAuthorizedUser: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [user, setUser] = useState<{ email: string } | null>(null)

  const submitRef = React.createRef<HTMLButtonElement>()

  const { id } = useParams()

  const navigate = useNavigate()

  const {
    register,
    setFocus,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resAddAuthorizedUser,
  })

  useInit({
    id,
    setUser,
    setDataLoaded,
    setShowOverlay,
    navigate,
  })

  useFocusError({
    errors,
    setFocus,
  })

  const navLink: string = !hasAnyRole([roles.admin])
    ? '/authorized-users'
    : '/authorized-users/' + id

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      showCardHeader={true}
      headerInsert={<BackButton url={navLink} text={'Authorized Users (' + user?.email + ')'} />}
      actionInsert={
        <Button
          disabled={!dataLoaded}
          type='submit'
          name='save'
          className='btn-round btn-primary'
          onClick={() => {
            if (submitRef.current != null) {
              submitRef.current.click()
            }
          }}
        >
          Save
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit((data) =>
          prepareSubmitForm({
            submitForm,
            navigate,
            data,
            setShowOverlay,
            id,
            name: 'Authorized User',
            successNav: navLink,
            setError,
          }),
        )}
        id='main-form'
      >
        <InputWrapper label='Email' name='email' errors={errors}>
          <Form.Control type='text' {...register('email')} />
        </InputWrapper>

        <Button type='submit' name='save' ref={submitRef} className='d-none'></Button>
      </Form>
    </Layout>
  )
}

export default AddAuthorizedUser

import getAll from 'utils/getAll'
import { apiUrls } from 'consts'
import ActionButtons from 'shared/components/ActionButtons'
import refreshCsv from 'utils/refreshCsv'

interface getRowsProps {
  setDeleteEntryId: Function
  setShowModal: Function
  setShowOverlay: Function
  setRefreshCsvCount: Function
}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.paymentDetails)

  data.forEach((row: any, index: number, dataArray: any) => {
    let actions: Array<any> = []

    const refreshCsvFunction = () => {
      refreshCsv({
        id: row.id,
        setShowOverlay: props.setShowOverlay,
        setRefreshCsvCount: props.setRefreshCsvCount,
      })
    }

    if (row.file_name?.endsWith('.csv')) {
      actions.push({
        type: 'refresh-data',
        id: row.id,
        function: refreshCsvFunction,
      })
    }

    actions.push(
      ...[
        {
          type: 'u',
          url: '/edit-statement-list/' + row.id,
        },
        {
          type: 'd',
          id: dataArray[index].id,
          url: '/delete-statement/' + row.id,
          setDeleteEntryId: props.setDeleteEntryId,
          setShowModal: props.setShowModal,
        },
      ],
    )

    dataArray[index].action = <ActionButtons buttons={actions} />
  })

  return data
}

export default getRows

const stringToColor = (str: string | null): string => {
  if (str === null) {
    return '#eeeeee'
  }
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  let red = (hash >> 0) & 0xff
  let green = (hash >> 8) & 0xff
  let blue = (hash >> 16) & 0xff

  blue = Math.min(blue + 50, 255)

  const color = `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}`
  return color
}

export default stringToColor

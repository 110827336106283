import { axiosPut } from 'shared/api/axios'
import { AxiosError } from 'axios'
import { apiUrls, roles } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import setFormValidationError from 'shared/utils/setFormValidationError'
import hasAnyRole from 'shared/utils/hasAnyRole'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  id: string | undefined
  setError: Function
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  const formData = {
    ...props.data?.address,
    id: props.id,
  }

  let mappedAttributes: Map<string, string> = new Map()
  mappedAttributes.set('street1', 'street1')
  mappedAttributes.set('street2', 'street2')
  mappedAttributes.set('city', 'city')
  mappedAttributes.set('postal_code', 'postalCode')
  mappedAttributes.set('country', 'country')
  mappedAttributes.set('region', 'region')
  mappedAttributes.set('phone', 'phone')

  const url = hasAnyRole([roles.admin])
    ? apiUrls.users + '/' + props.id + '/' + apiUrls.actionUpdateAddress
    : apiUrls.users + '/' + apiUrls.actionUpdateAddress

  response = await axiosPut(url, formData, (err: AxiosError) => {
    if (err.response!.status === 422) {
      setFormValidationError(err, props.setError, mappedAttributes, 'street1')
    } else {
      const message = err?.response?.data?.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
    }
  })

  return response
}

export default submitForm

import { useEffect } from 'react'
import Store from 'redux/Store'
import { getAllCustomData } from 'shared/components/App/Auth'
import { setCustomData } from 'shared/redux/actions'
import processUserChange from '../utils/processUserChange'
import displayError from 'shared/utils/displayError'

interface useInitUserChangeProps {
  currentAccountInfo: any
  initialRender: boolean
  dataLoaded: boolean
  setInitialRender: Function
  handleAccountInfoChanged?: Function
}

const useUserChange = (props: useInitUserChangeProps) => {
  useEffect(() => {
    if (!props.initialRender && props.dataLoaded) {
      processUserChange({
        user_id: props.currentAccountInfo?.id,
        setShowOverlay: (data) => {},
      }).then((ret: any) => {
        if (ret?.data === true) {
          let allCustomData = getAllCustomData()
          allCustomData.accountInfo = props.currentAccountInfo
          Store.dispatch(setCustomData(allCustomData))
        } else {
          //revert selected user
          displayError({ errorMessage: 'An error occurred while switching the account' })
        }
        if (props.handleAccountInfoChanged !== undefined) {
          props.handleAccountInfoChanged(props.currentAccountInfo)
        }
      })
    } else {
      props.setInitialRender(false)
    }
  }, [props.currentAccountInfo])
}

export default useUserChange

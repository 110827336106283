import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Row, Tooltip, OverlayTrigger, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import InputWrapper from 'shared/components/InputWrapper'
import resChangePassword from './resolvers/resChangePassword'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import submitForm from './utils/submitForm'
import * as Icon from 'react-bootstrap-icons'
import { togglePasswordInput, generateRandomPassword } from 'utils/passwordUtils'
import { loginViewModes } from 'consts'

interface SetNewPasswordProps {
  usernameFieldLabel?: string
  usernameFieldName?: string
  loginRoute: string
  setViewMode: Function
}

const SetNewPassword: React.FunctionComponent<SetNewPasswordProps> = (
  props: SetNewPasswordProps,
) => {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [pageLoaded, setPageLoaded] = useState<boolean>(false)
  const [usernameFieldLabel] = useState<string>(props.usernameFieldLabel ?? 'Username')
  const [usernameFieldName] = useState<string>(props.usernameFieldName ?? 'username')
  const [isSetNewPasswordRequestLoading, setIsSetNewPasswordRequestLoading] =
    useState<boolean>(false)

  const [passwordInputType, setPasswordInputType] = useState<string>('password')

  const { token } = useParams()
  const navigate = useNavigate()

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resChangePassword,
  })

  useInit({
    token: token ?? '',
    loginRoute: props.loginRoute,
    setPageLoaded,
    reset,
    setViewMode: props.setViewMode,
    navigate,
  })

  useFocusError({
    errors,
    setFocus,
  })

  return (
    <>
      {!pageLoaded && (
        <>
          <div className='color-overlay d-flex justify-content-center align-items-center'>
            <div className='lead py-5 my-5'>Checking Password Reset Token ...</div>
          </div>
        </>
      )}
      {pageLoaded && (
        <div className='color-overlay d-flex justify-content-center align-items-center'>
          <Form
            onSubmit={handleSubmit((data) =>
              submitForm({
                usernameFieldName,
                data,
                token,
                setIsSetNewPasswordRequestLoading,
                setErrorMsg,
                navigate,
                loginRoute: props.loginRoute,
                setViewMode: props.setViewMode,
                viewModeLogin: loginViewModes.login,
              }),
            )}
            id='main-form'
          >
            {errorMsg.length ? <p className='error'>{errorMsg}</p> : ''}

            <InputWrapper
              label={usernameFieldLabel}
              colAttributes={{ md: 12 }}
              name={usernameFieldName}
              errors={errors}
            >
              <Form.Control disabled={true} type='text' {...register(usernameFieldName)} />
            </InputWrapper>

            <InputWrapper
              colAttributes={{ md: 12 }}
              label='New Password'
              name='password'
              errors={errors}
            >
              <InputGroup className='mb-3'>
                <Form.Control type={passwordInputType} {...register('password')} />
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Generate Password</Tooltip>}
                >
                  <Button
                    variant='outline-primary'
                    onClick={() => setValue('password', generateRandomPassword())}
                  >
                    <Icon.Key title='Generate random password' />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      {passwordInputType === 'password' ? 'Show password' : 'Hide password'}
                    </Tooltip>
                  }
                >
                  <Button
                    variant='outline-warning'
                    onClick={() => setPasswordInputType(togglePasswordInput(passwordInputType))}
                  >
                    {passwordInputType === 'password' ? <Icon.Unlock /> : <Icon.Lock />}
                  </Button>
                </OverlayTrigger>
              </InputGroup>
            </InputWrapper>

            <Row>
              <div className='update ml-auto mr-auto'>
                <Button
                  disabled={isSetNewPasswordRequestLoading}
                  type='submit'
                  className='btn-round btn-primary'
                >
                  {isSetNewPasswordRequestLoading && (
                    <>
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                      &nbsp;
                    </>
                  )}
                  Set New Password
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      )}
    </>
  )
}

export default SetNewPassword

import { Card, Col } from 'react-bootstrap'

interface TotalRevenueProps {
  totalRevenue?: number
}

const TotalRevenue: React.FunctionComponent<TotalRevenueProps> = (props: TotalRevenueProps) => {
  return (
    <Col sm={12} md={6} className='mb-4'>
      <Card style={{ backgroundColor: '#4FC3F7', color: '#fff' }}>
        <Card.Body>
          <Card.Title>Total Revenue &gt;</Card.Title>
          <Card.Text style={{ fontSize: '2rem' }}>
            {props?.totalRevenue?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default TotalRevenue

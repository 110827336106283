import { axiosPut } from 'shared/api/axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import { AxiosError } from 'axios'
import Store from 'redux/Store'
import { setMessage } from 'redux/actions'

interface submitFormProps {
  id: string | undefined
  setShowOverlay: Function
  setActivationClickCount: Function
}

const activate = async (props: submitFormProps) => {
  props.setShowOverlay(true)

  let response: any = false

  const formData = {}

  const catchFunc = (err: AxiosError) => {
    const message = err?.response?.data?.message ?? 'An error has occurred'
    displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
  }

  response = await axiosPut(
    apiUrls.authorizedUsers + '/' + props.id + '/' + apiUrls.actionActivate,
    formData,
    catchFunc,
  )

  if (response?.status === 200) {
    Store.dispatch(setMessage('User successfully activated.', 'success', 'instant'))
    props.setActivationClickCount((prevValue) => prevValue + 1)
  }
  props.setShowOverlay(false)
}

export default activate

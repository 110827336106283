import { connect } from 'react-redux'
import HeaderUserSelect from 'components/HeaderUserSelect'

const mapStateToProps = (state: any) => {
  return {
    currentAccountInfo: state?.customData?.customData?.accountInfo,
  }
}

export default connect(mapStateToProps)(HeaderUserSelect)

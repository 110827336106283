import { useEffect } from 'react'

interface useInitProps {
  currentEntity: {} | null
  currentAccountInfo: {} | null
  getRows: Function
  setRows: Function
  setShowOverlay: Function
  setSelection: Function
  setAnalyticsData: Function
  reset: Function
  setPaymentDetailCsvIdsSelected: Function
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    props.getRows().then((data: any) => {
      props.setRows(data)
      props.setAnalyticsData(null)
      props.setSelection([])
      props.setPaymentDetailCsvIdsSelected([])
      props.reset()
      props.setShowOverlay(false)
    })
  }, [props.currentEntity, props.currentAccountInfo])
}

export default useInit

import getAll from 'utils/getAll'
import { apiUrls, roles } from 'consts'
import hasAnyRole from 'shared/utils/hasAnyRole'
import ActionButtons from 'shared/components/ActionButtons'

interface getRowsProps {
  setDeleteEntryId: Function
  setShowModal: Function
}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.users)

  const isAdmin = hasAnyRole([roles.admin])

  data.forEach((row: any, index: number, dataArray: any) => {
    let buttonActions: Array<{}> = []
    if (isAdmin) {
      buttonActions.push({
        type: 'p',
        id: dataArray[index].id,
        url: '/change-password/' + row.id,
        setDeleteEntryId: props.setDeleteEntryId,
        setShowModal: props.setShowModal,
      })

      if (dataArray[index].is_trolley_user) {
        buttonActions.push({
          type: 'a',
          id: dataArray[index].id,
          url: '/change-address/' + row.id,
          setDeleteEntryId: props.setDeleteEntryId,
          setShowModal: props.setShowModal,
        })
        buttonActions.push({
          type: 'au',
          id: dataArray[index].id,
          url: '/authorized-users/' + row.id,
          setDeleteEntryId: props.setDeleteEntryId,
          setShowModal: props.setShowModal,
        })
      }

      buttonActions.push({
        type: 'aa',
        id: dataArray[index].id,
        url: '/authorized-user-access/' + row.id,
        setDeleteEntryId: props.setDeleteEntryId,
        setShowModal: props.setShowModal,
      })

      dataArray[index].action = <ActionButtons buttons={buttonActions} />
    }
  })

  return data
}

export default getRows

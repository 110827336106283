import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import HeaderEntitySelect from 'containers/HeaderEntitySelect'
import hasAnyRole from 'shared/utils/hasAnyRole'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { roles } from 'consts'
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4'
import {
  Column,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import useInit from './hooks/useInit'
import getRows from './utils/getRows'
import { Accordion, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import showAnalytics from './utils/showAnalytics'
import { FilePdf, Filter, GraphUpArrow } from 'react-bootstrap-icons'
import PieChart from './components/PieChart'
import AnalyticsDataProps from './types/AnalyticsProps'
import TerritoriesBarChart from './components/TerritoriesBarChart'
import SourcesBarChart from './components/SourcesBarChart'
import SubSourcesBarChart from './components/SubSourcesBarChart'
import ConfigurationsTable from './components/ConfigurationsTable'
import TopReleasesTable from './components/TopReleasesTable'
import TopTracksTable from './components/TopTracksTable'
import { useRef } from 'react'
import exportToPDF from './utils/exportToPDF'
import { useForm } from 'react-hook-form'
import HeaderUserSelect from 'containers/HeaderUserSelect'
import showUserSwitcher from 'utils/showUserSwitcher'
import TotalRevenue from './components/TotalRevenue'
import TotalUnits from './components/TotalUnits'

const Analytics: React.FunctionComponent = () => {
  const [rows, setRows] = useState<any>([])
  const [analyticsData, setAnalyticsData] = useState<AnalyticsDataProps | null>(null)
  const [isAnalyticsRequestRunning, setIsAnalyticsRequestRunning] = useState<boolean>(false)
  const [isPdfExportRequestRunning, setIsPdfExportRequestRunning] = useState<boolean>(false)
  const [paymentDetailCsvIdsSelected, setPaymentDetailCsvIdsSelected] = useState<any>([])
  const [selection, setSelection] = useState<Array<any>>([])
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [currentEntity, setCurrentEntity] = useState<{} | null>({})
  const [currentAccountInfo, setCurrentAccountInfo] = useState<{} | null>({})

  const territoriesChartRef = useRef<HTMLDivElement>(null)
  const sourcesChartRef = useRef<HTMLDivElement>(null)
  const subSourcesChartRef = useRef<HTMLDivElement>(null)
  const topReleasesRef = useRef<HTMLDivElement>(null)
  const topTracksRef = useRef<HTMLDivElement>(null)
  const bundledDataRef = useRef<HTMLDivElement>(null)

  const detailColumns: ReadonlyArray<Column> = [
    { name: 'company_name', title: 'Company' },
    { name: 'statement_period', title: 'Statement Period' },
    { name: 'file_name', title: 'Filename' },
  ]

  const detailColumnWidths: TableColumnWidthInfo[] = [
    { columnName: 'company_name', width: '25%' },
    { columnName: 'statement_period', width: '25%' },
    { columnName: 'file_name', width: '25%' },
  ]

  const { handleSubmit, reset, register } = useForm({})

  useInit({
    currentEntity,
    currentAccountInfo,
    getRows,
    setRows,
    setShowOverlay,
    setSelection,
    setAnalyticsData,
    reset,
    setPaymentDetailCsvIdsSelected,
  })

  const handleReset = () => {
    reset()
  }

  const onSubmit = (data) => {
    handleShowAnalytics(data)
  }

  const handleShowAnalytics = (filters: object) => {
    showAnalytics({
      filters: filters,
      paymentDetailCsvIdsSelected: paymentDetailCsvIdsSelected,
      setIsAnalyticsRequestRunning: setIsAnalyticsRequestRunning,
      setAnalyticsData: setAnalyticsData,
      setShowOverlay: setShowOverlay,
    })
  }

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  const onSelectionChanged = (currSelection: Array<number | string>) => {
    const filteredRows = rows.filter((_, index) => currSelection.includes(index))
    setPaymentDetailCsvIdsSelected(filteredRows.map((obj) => obj.id))
    setSelection(currSelection)
  }

  const handleExportToPDF = () => {
    if (analyticsData !== null) {
      exportToPDF({
        setIsPdfExportRequestRunning,
        setShowOverlay,
        analyticsData,
        territoriesChartRef,
        sourcesChartRef,
        subSourcesChartRef,
        topReleasesRef,
        topTracksRef,
        bundledDataRef,
      })
    }
  }

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      headerInsert={
        hasAnyRole([roles.admin]) ? (
          <HeaderEntitySelect
            setShowOverlay={setShowOverlay}
            handleEntityChanged={(entity) => {
              setShowOverlay(false)
              setCurrentEntity(entity)
            }}
          />
        ) : hasAnyRole([roles.user, roles.authorizedUser]) && showUserSwitcher() ? (
          <HeaderUserSelect
            setShowOverlay={setShowOverlay}
            handleAccountInfoChanged={(currentAccountInfo) => {
              setCurrentAccountInfo(currentAccountInfo)
            }}
          />
        ) : undefined
      }
    >
      <>
        <Accordion defaultActiveKey={'0'}>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Files</Accordion.Header>
            <Accordion.Body>
              <Grid rows={rows} columns={detailColumns}>
                <SortingState />
                <IntegratedSorting />
                <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                <IntegratedPaging />
                <SelectionState selection={selection} onSelectionChange={onSelectionChanged} />
                <IntegratedSelection />
                <Table />
                <TableColumnResizing
                  defaultColumnWidths={detailColumnWidths}
                  resizingMode='nextColumn'
                />
                <TableSelection selectByRowClick={true} showSelectAll={true} />
                <TableHeaderRow />
                <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />
              </Grid>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Row className='my-2'>
          <Col>
            <Button
              disabled={selection.length === 0}
              onClick={(e) => handleShowAnalytics({})}
              variant='primary'
              size='sm'
              className='float-right'
            >
              {isAnalyticsRequestRunning ? (
                <>
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                  &nbsp;
                </>
              ) : (
                <GraphUpArrow />
              )}
              &nbsp; Analyze
            </Button>
          </Col>
        </Row>

        {analyticsData !== null && (
          <>
            <Accordion>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>Filters</Accordion.Header>
                <Accordion.Body>
                  <>
                    <Form onSubmit={handleSubmit(onSubmit)} id='filter-payments-form'>
                      <Row>
                        <Form.Text muted className='mb-3'>
                          {'Separate multiple values with semicolon'}
                        </Form.Text>
                        <Col md={6}>
                          <Form.Group className='form-group'>
                            <Form.Label>Release Title</Form.Label>
                            <Form.Control type='text' {...register('release_title')} />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className='form-group'>
                            <Form.Label>Track Title</Form.Label>
                            <Form.Control type='text' {...register('track_title')} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group className='form-group'>
                            <Form.Label>Release Artist</Form.Label>
                            <Form.Control type='text' {...register('release_artist')} />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className='form-group'>
                            <Form.Label>Track Artist</Form.Label>
                            <Form.Control type='text' {...register('track_artist')} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group className='form-group'>
                            <Form.Label>Territory</Form.Label>
                            <Form.Control type='text' {...register('territory')} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='my-2'>
                        <Col>
                          <Button
                            disabled={selection.length === 0}
                            variant='primary'
                            size='sm'
                            type='submit'
                            className='float-right'
                          >
                            {isAnalyticsRequestRunning ? (
                              <>
                                <span
                                  className='spinner-border spinner-border-sm'
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                                &nbsp;
                              </>
                            ) : (
                              <Filter />
                            )}
                            &nbsp; Filter
                          </Button>

                          <Button
                            onClick={() => {
                              handleReset()
                              onSubmit({})
                            }}
                            variant='primary'
                            size='sm'
                            type='reset'
                            className='float-right btn-margin'
                          >
                            Reset
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Container fluid className='mt-4'>
              <Row>
                <TotalRevenue totalRevenue={analyticsData?.totalRevenue} />
                <TotalUnits totalUnits={analyticsData?.totalUnits} />
              </Row>

              <Row className='d-none' ref={bundledDataRef}>
                <h1>Analytics Report</h1>

                <Row>
                  <TotalRevenue totalRevenue={analyticsData?.totalRevenue} />
                  <TotalUnits totalUnits={analyticsData?.totalUnits} />
                </Row>

                <Col sm={6} md={6} className='mb-4 offset-md-3 offset-sm-3'>
                  <PieChart data={analyticsData.configurations} />
                </Col>

                <Col sm={12} md={12} className='mb-4'>
                  <ConfigurationsTable data={analyticsData.configurations} />
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={6} className='mb-4'>
                  <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    <ConfigurationsTable data={analyticsData.configurations} />
                  </div>
                </Col>
                <Col sm={12} md={6} className='mb-4'>
                  <PieChart data={analyticsData.configurations} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} className='mb-4' ref={territoriesChartRef}>
                  <TerritoriesBarChart data={analyticsData.territories} />
                </Col>
                <Col sm={12} md={12} className='mb-4' ref={sourcesChartRef}>
                  <SourcesBarChart data={analyticsData.sources} />
                </Col>
                <Col sm={12} md={12} className='mb-4' ref={subSourcesChartRef}>
                  <SubSourcesBarChart data={analyticsData.subSources} />
                </Col>
                <Col sm={12} md={12} className='mb-4' ref={topReleasesRef}>
                  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <TopReleasesTable data={analyticsData.topReleases} />
                  </div>
                </Col>
                <Col sm={12} md={12} className='mb-4' ref={topTracksRef}>
                  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <TopTracksTable data={analyticsData.topTracks} />
                  </div>
                </Col>
              </Row>

              <Button
                disabled={isPdfExportRequestRunning}
                onClick={handleExportToPDF}
                variant='primary'
                size='sm'
                className='float-right'
              >
                {isPdfExportRequestRunning ? (
                  <>
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    &nbsp;
                  </>
                ) : (
                  <FilePdf />
                )}
                &nbsp; Export to PDF
              </Button>
            </Container>
          </>
        )}
      </>
    </Layout>
  )
}

export default Analytics

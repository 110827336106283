import { Form, Row, Col, Button, Collapse } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as Icon from 'react-bootstrap-icons'
import { useState } from 'react'
import submitFilterForm from './utils/submitFilterForm'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { paymentStatuses, roles } from 'consts'
import SelectWrapper from 'shared/components/SelectWrapper'

interface PaymentsFilterProps {
  handleFilter: Function
  setShowOverlay: Function
  currentEntity: {} | null
  filterVisible: boolean
}

const PaymentsFilter: React.FunctionComponent<PaymentsFilterProps> = ({
  handleFilter,
  setShowOverlay,
  currentEntity,
  filterVisible,
}: PaymentsFilterProps) => {
  const { handleSubmit, reset, setValue, register, getValues, control } = useForm({})

  const handleReset = () => {
    reset()
    setValue('payment_statuses', null)
  }

  const onSubmit = (data) => {
    setShowOverlay(true)
    submitFilterForm({
      data,
      handleFilter,
    })
  }

  const isAdmin: boolean = hasAnyRole([roles.admin])

  //remove pending status for users (they do not see peding payments)
  const statuses: Array<any> = paymentStatuses.filter((status, index) => {
    return isAdmin || status.name !== 'pending'
  })

  return (
    <>
      <div className='d-flex flex-column'>
        <Collapse in={filterVisible}>
          <div className='card-header position-relative payments-filter'>
            <Form onSubmit={handleSubmit(onSubmit)} id='filter-payments-form'>
              <Row>
                <Col md={6}>
                  <Form.Group className='form-group'>
                    <Form.Label>Date From</Form.Label>
                    <Form.Control type='date' {...register('date_from')} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='form-group'>
                    <Form.Label>Date To</Form.Label>
                    <Form.Control type='date' {...register('date_to')} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className='form-group'>
                    <Form.Label>USD Amount From</Form.Label>
                    <Form.Control type='number' step='any' min='0' {...register('amount_from')} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='form-group'>
                    <Form.Label>USD Amount To</Form.Label>
                    <Form.Control type='number' step='any' min='0' {...register('amount_to')} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className='form-group'>
                    <Form.Label>Payment Status</Form.Label>
                    <SelectWrapper
                      name='payment_statuses'
                      isClearable={true}
                      isMulti={true}
                      control={control}
                      placeholder=''
                      defaultValue={[]}
                      options={statuses}
                      optionLabel='name'
                      optionValue='name'
                    />
                  </Form.Group>
                </Col>
                {isAdmin && (
                  <Col md={6}>
                    <Form.Group className='form-group'>
                      <Form.Label>Recipient Name</Form.Label>
                      <Form.Control type='text' {...register('recipient_name')} />
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row className='filter-buttons'>
                <Col>
                  <Button
                    variant='primary'
                    size='sm'
                    type='submit'
                    name='submit2'
                    className='float-right btn-margin'
                  >
                    <Icon.Search />
                    &nbsp; Search
                  </Button>

                  <Button
                    onClick={() => {
                      handleReset()
                      onSubmit({})
                    }}
                    variant='primary'
                    size='sm'
                    type='reset'
                    className='float-right btn-margin'
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default PaymentsFilter

import { axiosPut } from 'shared/api/axios'
import { apiUrls } from 'consts'

interface submitFormProps {
  user_id: number
  setShowOverlay: Function
}

const processUserChange = async (props: submitFormProps) => {
  const payload = {
    user_id: props.user_id,
  }
  return await axiosPut(apiUrls.users + '/' + apiUrls.actionChangeUser, payload, () => {})
}

export default processUserChange

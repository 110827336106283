import { apiUrls } from 'consts'
import { navigate } from 'ionicons/icons'
import { useEffect } from 'react'
import Store from 'redux/Store'
import { setMessage } from 'redux/actions'
import getAll from 'utils/getAll'

interface useInitProps {
  getValues: Function
  id: string | undefined
  reset: Function
  setDataLoaded: Function
  setShowOverlay: Function
  navigate: Function
  currentAccountInfo: {} | null
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    const url: string =
      props.id === undefined
        ? apiUrls.users + '/' + apiUrls.actionUpdateAddress
        : apiUrls.users + '/' + props.id + '/' + apiUrls.actionUpdateAddress
    getAll(url).then((data) => {
      if (!data.is_trolley_user) {
        Store.dispatch(
          setMessage(
            'Please, select a Trolley User to access Address Page.',
            'warning',
            'onNavigate',
          ),
        )
        props.navigate('/')
      }
      props.reset(data)
      props.setDataLoaded(true)
      props.setShowOverlay(false)
    })
  }, [props.currentAccountInfo])
}

export default useInit

import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import stringToColor from '../utils/stringToColor'
import { Configuration } from '../types/AnalyticsProps'

ChartJS.register(ArcElement, Tooltip, Legend)

interface PieChartProps {
  data: Configuration[]
}

const PieChart: React.FunctionComponent<PieChartProps> = (props: PieChartProps) => {
  const totalSum = props.data.reduce((acc, item) => acc + item.total, 0)

  const colors = props.data.map((item) => stringToColor(item.configuration))

  const chartData = {
    labels: props.data.map((item) => item.configuration),
    datasets: [
      {
        label: 'Configurations',
        data: props.data.map((item) => item.total),
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  }

  const options = {
    responsive: true,
    cutout: '50%',
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          generateLabels: (chart) => {
            const dataset = chart.data.datasets[0]
            const total = dataset.data.reduce((acc, value) => acc + value, 0)
            return chart.data.labels.map((label, i) => {
              const value = dataset.data[i]
              const percentage = ((value / total) * 100).toFixed(2)
              return {
                text: `${label} - ${percentage}%`,
                fillStyle: dataset.backgroundColor[i],
                hidden: chart.getDatasetMeta(0).data[i].hidden,
                index: i,
              }
            })
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw
            const percentage = ((value / totalSum) * 100).toFixed(2)
            return `${tooltipItem.label}: ${percentage}%`
          },
        },
      },
    },
  }

  return (
    <div style={{ width: '90%', margin: '0 auto' }}>
      <Doughnut data={chartData} options={options} />
    </div>
  )
}

export default PieChart

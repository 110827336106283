import React, { useState } from 'react'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4'
import {
  Column,
  TableColumnWidthInfo,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid'
import { Configuration } from '../types/AnalyticsProps'
import formatCurrency from '../utils/formatCurrency'

interface ConfigurationsGridProps {
  data: Configuration[]
}

const ConfigurationsTable: React.FunctionComponent<ConfigurationsGridProps> = ({ data }) => {
  const columns: ReadonlyArray<Column> = [
    { name: 'configuration', title: 'Configuration' },
    { name: 'total', title: 'Value' },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'configuration', width: 'auto' },
    { columnName: 'total', width: 'auto' },
  ]

  const [columnExtensions] = useState<Array<Table.ColumnExtension>>([
    { columnName: 'total', align: 'right' },
  ])

  const TableCell = ({ value, column }: any) => {
    if (column.name === 'total') {
      return <td className='text-right'>{formatCurrency(value)}</td>
    }
    return <td>{value}</td>
  }

  return (
    <>
      <h4>Configurations</h4>
      <Grid rows={data} columns={columns}>
        <SortingState />
        <IntegratedSorting />
        <Table cellComponent={TableCell} columnExtensions={columnExtensions} />
        <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
        <TableHeaderRow showSortingControls />
      </Grid>
    </>
  )
}

export default ConfigurationsTable

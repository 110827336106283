import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup
  .object({
    email: yup
      .string()
      .label('Email')
      .trim()
      .email()
      .required('Email is required')
      .min(5, 'Email must be at least 5 characters'),
  })
  .required()

const resAddAuthorizedUser = yupResolver(schema)

export default resAddAuthorizedUser

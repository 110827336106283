import { useEffect } from 'react'

interface useInitProps {
  getRows: Function
  setRows: Function
  setShowOverlay: Function
  filter?: {} | null
  currentEntity: {} | null
  currentAccountInfo: {} | null | undefined
  filteredPayment?: string
  refreshCsvCount?: number
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    if (props.filter !== null) {
      props
        .getRows({
          filter: props.filter,
        })
        .then((data: any) => {
          props.setRows(data)
          props.setShowOverlay(false)
        })
    }
  }, [
    props.filter,
    props.currentEntity,
    props.currentAccountInfo,
    props.filteredPayment,
    props.refreshCsvCount,
  ])
}

export default useInit

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { RefObject } from 'react'
import Analytics from '../types/AnalyticsProps'

interface exportToPDFProps {
  setIsPdfExportRequestRunning: Function
  setShowOverlay: Function
  analyticsData: Analytics
  territoriesChartRef: RefObject<HTMLDivElement>
  sourcesChartRef: RefObject<HTMLDivElement>
  subSourcesChartRef: RefObject<HTMLDivElement>
  topReleasesRef: RefObject<HTMLDivElement>
  topTracksRef: RefObject<HTMLDivElement>
  bundledDataRef: RefObject<HTMLDivElement>
}

export const exportToPDF = async (props: exportToPDFProps) => {
  props.setShowOverlay(true)
  props.setIsPdfExportRequestRunning(true)
  const doc = new jsPDF('p', 'mm', 'a4')
  let yOffset: number = 10

  const addElementToPDF = async (
    ref: React.RefObject<HTMLDivElement>,
    xOffset: number = 10,
    yOffset: number = 10,
  ): Promise<number> => {
    if (ref.current) {
      const canvas = await html2canvas(ref.current)
      const imgData = canvas.toDataURL('image/png')
      const imgHeight = (canvas.height * 190) / canvas.width

      doc.addImage(imgData, 'PNG', xOffset, yOffset, 190, imgHeight)

      return imgHeight + 10
    }

    return 0
  }

  const showHiddenElement = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.classList.remove('d-none')
    }
  }

  const hideElement = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.classList.add('d-none')
    }
  }

  const increaseH4SizeTemporarily = () => {
    document.querySelectorAll('h4').forEach((el) => {
      el.style.fontSize = '28px'
    })
  }

  const resetH4Size = () => {
    document.querySelectorAll('h4').forEach((el) => {
      el.style.fontSize = '' // Reset to original
    })
  }

  const addTwoElementsOnSamePage = async (
    ref1: RefObject<HTMLDivElement>,
    ref2: RefObject<HTMLDivElement>,
  ) => {
    const imgHeight1 = await addElementToPDF(ref1, 10, yOffset)
    yOffset += imgHeight1

    const imgHeight2 = await addElementToPDF(ref2, 10, yOffset)
    yOffset += imgHeight2
  }

  const addElementWithDelay = async (ref: RefObject<HTMLDivElement>, delay: number) => {
    return new Promise<void>((resolve) => {
      setTimeout(async () => {
        await addElementToPDF(ref)
        resolve()
      }, delay)
    })
  }

  if (props.analyticsData) {
    increaseH4SizeTemporarily()

    showHiddenElement(props.bundledDataRef)
    await addElementWithDelay(props.bundledDataRef, 500)
    hideElement(props.bundledDataRef)

    doc.addPage()

    await addTwoElementsOnSamePage(props.territoriesChartRef, props.sourcesChartRef)

    doc.addPage()
    await addElementWithDelay(props.subSourcesChartRef, 500)

    doc.addPage()
    await addElementWithDelay(props.topReleasesRef, 500)

    doc.addPage()
    await addElementWithDelay(props.topTracksRef, 500)

    resetH4Size()

    doc.save('analytics_report.pdf')

    props.setIsPdfExportRequestRunning(false)
    props.setShowOverlay(false)
  }
}

export default exportToPDF

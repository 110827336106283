import { useEffect } from 'react'

interface useInitPaymentDetailsProps {
  getRows: Function
  setRows: Function
  setDeleteEntryId: Function
  setShowModal: Function
  setShowOverlay: Function
  setRefreshCsvCount: Function
  refreshCsvCount: number
  currentEntity: {} | null
}

const useInit = (props: useInitPaymentDetailsProps) => {
  useEffect(() => {
    props
      .getRows({
        setDeleteEntryId: props.setDeleteEntryId,
        setShowModal: props.setShowModal,
        setShowOverlay: props.setShowOverlay,
        setRefreshCsvCount: props.setRefreshCsvCount,
      })
      .then((data: any) => {
        props.setRows(data)
        props.setShowOverlay(false)
      })
  }, [props.currentEntity, props.refreshCsvCount])
}

export default useInit

import { useEffect } from 'react'
import { getAllCustomData, getCustomData } from 'shared/components/App/Auth'
import Store from 'redux/Store'
import { setCustomData } from 'shared/redux/actions'

interface useInitProps {
  getRows: Function
  setRows: Function
  setCurrentAccountInfo: Function
  setDataLoaded: Function
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    props.getRows().then((data: any) => {
      props.setRows(data.users)
      const currentUser = data.selectedUser ?? getCustomData('accountInfo')
      const userObj = data.users.find((row) => row.id === currentUser?.id)
      props.setCurrentAccountInfo(userObj)

      let allCustomData = getAllCustomData()
      allCustomData.accountInfo = userObj
      Store.dispatch(setCustomData(allCustomData))

      props.setDataLoaded(true)
    })
  }, [])
}

export default useInit

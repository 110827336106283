import { AxiosError } from 'axios'
import { apiUrls } from 'consts'
import { axiosPost } from 'shared/api/axios'
import displayGenError from 'shared/utils/displayGenError'

interface showAnalyticsProps {
  filters: object
  paymentDetailCsvIdsSelected?: any
  setIsAnalyticsRequestRunning: Function
  setAnalyticsData: Function
  setShowOverlay: Function
}

const showAnalytics = async (props: showAnalyticsProps) => {
  props.setIsAnalyticsRequestRunning(true)

  let data: Array<number> = props.paymentDetailCsvIdsSelected

  await axiosPost(
    apiUrls.analytics + '/' + apiUrls.actionGetData,
    { paymentDetailCsvIds: data, filters: props.filters },
    async (err: AxiosError) => {
      let errData = err?.response?.data ?? []

      const message = errData.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
      props.setIsAnalyticsRequestRunning(false)
    },
    false,
  ).then((response) => {
    props.setAnalyticsData(response.data)
    props.setIsAnalyticsRequestRunning(false)
  })
}

export default showAnalytics
